import axios from 'axios'
import qs from 'qs';
import {
	ElMessage,
	Message,
	Notification
} from 'element-plus'
import Router from '@/router'
const service = axios.create({
	// baseURL: 'http://localhost:8886/',  
	baseURL: 'http://47.105.43.195:8886/',
	withCredentials: true,
	headers: {
		"Content-Type": 'application/x-www-form-urlencoded;charset=UTF-8'
		// "Content-Type": "application/json; charset=UTF-8;"
	},
	timeout: 3000000
})
service.interceptors.request.use(
	config => {
		config.headers.type = 'PC';
		return config;
	},
	err => {
		return Promise.reject(err);
	}
)
export function getRefreshToken() { // 刷新token
	return axios.post(`freshtoken`).then(data => {
		return Promise.resolve(data.data)
	});

}
service.interceptors.request.use(
	config => {
		const token = localStorage.getItem('token');
		const sessionId = localStorage.getItem('sessionId');
		if (token) { // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加
			config.headers.Authorization = `${token}`;
			config.headers.sessionId = `${sessionId}`;
			let timestamp = new Date().getTime();
			//过了这个时间后需要刷新
			let expires = Number(localStorage.getItem("expires"));
			//console.log(expires);
			//token存在的有效时间
			let refresh_expires_in = Number(localStorage.getItem("refresh_expires_in"))
			if (expires < timestamp) {
				if (timestamp < refresh_expires_in) {
					//刷新token-----
					localStorage.removeItem("token");
					localStorage.removeItem("expires");
					localStorage.removeItem("refresh_expires_in");
					getRefreshToken().then(res => {
						let token2 = res.data.token;
						let expires2 = res.data.freshdate;
						let refresh_expires_in2 = res.data.validate;
						localStorage.setItem('token', token2)
						localStorage.setItem('expires', expires2)
						localStorage.setItem('refresh_expires_in', refresh_expires_in2)
						config.headers.Authorization = localStorage.token;
						config.headers.sessionId = localStorage.sessionId;
						config.data = qs.stringify(res);
						return config
					}).catch(err => {
						localStorage.clear(); //清除local
						ElMessage({
							message: '网络异常，请重新登陆',
							type: 'warning',
						})
						Router.replace({
							path: '/login'
						})
						config.data = qs.stringify(res);
						return config
					})
				} else {
					ElMessage({
						message: '登陆过期，请重新登陆',
						type: 'warning',
					})
					localStorage.clear(); //清除local
					Router.replace({
						path: '/login'
					})
					config.data = qs.stringify(res);
					return config;
				}
			}
		}
		return config;
	},
	err => {
		return Promise.reject(err);
	});

// response 拦截
var temnum1 = 0;
var temnum2 = 1;
service.interceptors.response.use(
	response => {
		const res = response;
		return res;
	},
	error => {
		if (error && error.response) {
			switch (error.response.status) {
				case 401:
					temnum1 += 1;
					if (temnum1 == temnum2) {
						ElMessage({
							message: '登陆过期，请重新登陆',
							type: 'warning',
						})
					}
					// 返回 401 清除token信息并跳转到登录页面
					localStorage.removeItem('token');
					console.log('401');
					Router.replace({
						path: '/login',
					});
				default:
					temnum1 += 1;
					if (temnum1 == temnum2) {
						ElMessage({
							message: '未知错误，稍后再试!',
							type: 'warning',
						})
					}

			}
		} else {
			temnum1 += 1;
			if (temnum1 == temnum2) {
				ElMessage({
					message: '网络出现问题，请稍后再试!',
					type: 'warning',
				})
			}

		}
		return Promise.reject(error) // 返回接口返回的错误信息
	})

export default service